import Catalog from "./appCatalog/Catalog";
import Basket from "./appBasket/Basket";
import BasketDelivery from "./appBasketDelivery/Delivery";
import BasketPayment from "./appBasketPayment/Payment";
import Auth from "./appTelegramAuth/TelegramAuth";

import Order from "./appOrder/Order";

import OrderManager from "./appOrderManager/OrderManager";
import OrderCourier from "./appOrderCourier/OrderCourier";

import Profile from "./appProfile/Profile";
import { Route, Routes } from "react-router-dom";
import "@fontsource/inter";

// import "@fontsource/inter/inter";
import "./App.css";
import Info from "./appInfo/info";
import InfoDetail from "./appInfo/infoDetail";

//+++костыль от смахивания в ТГ
// if (window.scrollY === 0) {
//   window.scrollTo(0, 1);
// }

window.addEventListener("scroll", function (e) {
  if (window.scrollY === 0) {
    window.scrollTo(0, 1);
  }
});
//---костыль от смахивания в ТГ

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Catalog />} />
      <Route path="/tgauth/" element={<Auth />} />
      <Route path="/basket/" element={<Basket />} />
      <Route path="/basket/delivery/" element={<BasketDelivery />} />
      <Route path="/basket/payment/" element={<BasketPayment />} />
      <Route path="/profile/" element={<Profile />} />
      <Route path="/order/" element={<Order />} />
      <Route path="/info/" element={<Info />} />
      <Route path="/info/:uuid/" element={<InfoDetail />} />

      <Route path="/manager/order/" element={<OrderManager />} />
      <Route path="/courier/order/" element={<OrderCourier />} />
    </Routes>
  );
};

export default App;
