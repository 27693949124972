import "./footer.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../index";

import {
  IconCoffeeCupMenu,
  IconBasket,
  IconOrders,
  IconList,
  IconProfile,
} from "./icons/icons";

const Footer = (props) => {

  const [footerInfo, setFooterInfo] = useState();

  useEffect(() => {

    updateFooter();
    let timerId = setInterval(
      () => {
        updateFooter();
      }, //0
      20000
    );

    return () => {
      clearInterval(timerId);
    };

  }, []);

  const updateFooter = () => {
    axios.get(apiUrl("order/active")).then((data) => {
      setFooterInfo(data.data);
    });
  }

  return (
    <div id="footer" className="fixed-bottom">
      <Container>
        <Row className="text-center g-1">
          <Col>
            <NavLink
              to={"/"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <IconCoffeeCupMenu className="icon" />
              Меню
            </NavLink>
          </Col>
          <Col>
            <NavLink
              to={"/basket/"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <div className="icon">
                <IconBasket />
                <span className="basket basket-count-value"></span>
              </div>
              Корзина
            </NavLink>
          </Col>

          <Col>
            <NavLink
              to={"/order/"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <div className="icon">
                <IconOrders />
                {footerInfo?.client > 0 && (
                  <span className="client basket-count-value">{footerInfo?.client}</span>
                )}
              </div>
              Мои заказы
            </NavLink>
          </Col>
          {footerInfo?.manager !== undefined && (
            <Col>
              <NavLink
                to={"/manager/order/"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="icon">
                  <IconOrders />
                  {footerInfo?.manager > 0 && (
                    <span className="manager basket-count-value">{footerInfo?.manager}</span>
                  )}
                </div>
                Менеджер
              </NavLink>
            </Col>
          )}

          {footerInfo?.courier !== undefined && (
            <Col>
              <NavLink
                to={"/courier/order/"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="icon">
                  <IconOrders />
                  {footerInfo?.courier > 0 && (
                    <span className="courier basket-count-value">{footerInfo?.courier}</span>
                  )}
                </div>
                Курьер
              </NavLink>
            </Col>

          )}


          {/* <Col>
            <Link to={"/profile/"}>
              <div className="icon">
                <IconProfile />
              </div>
              Профиль
            </Link>
          </Col> */}
          <Col>
            <NavLink
              to={"/info/"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <div className="icon">
                <IconList />
              </div>
              Ещё
            </NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
