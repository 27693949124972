import { Link } from "react-scroll";
import { Col, Row } from "react-bootstrap";

//import { useEffect, useState, useCallback } from "react";

const ListCategories = (props) => {
  const { catalog } = props;


  const handleSetActive = (to, id) => {
    const sc = document.getElementById(`s-${id}`);
    const el = document.getElementById("hs");

    const ac = document.getElementById("categories").getElementsByClassName("active");
    for (let i = 0; i < ac.length; i++) {
      ac[i].classList.remove("active");
    }

    sc.classList.add("active");
    el.scrollLeft = sc.offsetLeft - (window.innerWidth / 2 - sc.offsetWidth / 2);

  };


  // const [scroll, setScroll] = useState(0);
  // const onScroll = useCallback(() => {
  //   setScroll(Math.round(window.scrollY));
  // }, []);

  // useEffect(() => {
  //   onScroll();
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [onScroll]);

  return (
    <Row id="categories" className="text-center nav-scroller">
      <Col>
        <nav id={"hs"} className="nav-scroller__items dragscroll">
          {catalog?.categories?.map((category) => (
            <Link
              activeClass="active"
              duration={0}
              // delay={0}
              smooth={false}
              spy={true}
              // spyThrottle={550}
              // isDynamic={true}
              // ignoreCancelEvents={true}
              offset={-50}
              key={category.id}
              id={`s-${category.id}`}
              to={`q${category.id}`}
              className="btn btn-basic nav-scroller__item"
              onSetActive={(to) => { handleSetActive(to, category.id) }}
              onClick={(to) => { handleSetActive(to, category.id) }}

            >
              {category.name}
            </Link>
          ))}
        </nav>
      </Col>
    </Row>
  );
};

export default ListCategories;
