import Card from "react-bootstrap/Card";

import { useEffect, useState } from "react";
import { Currency, addBackLevel } from "../index";
import ListCategory from "./ListCategory";
import ModalProduct from "./ModalProduct";

import { Container, Col, Row } from "react-bootstrap";

const ListProducts = (props) => {
  const { catalog } = props;

  const [productData, setProductData] = useState();

  const handleClick = (product) => {
    setProductData(product);
  };

  const handleCloseModal = () => {
    setProductData();
  };

  return (
    <Container id="content">
      <ListCategory catalog={catalog} />

      {catalog?.categories?.map((category) => (
        // <InView onChange={(v) => { setInView(v, category) }}>
        <Row key={`q${category.id}`} id={`q${category.id}`}>
          <Col xs={12} key={category.id} className="mt-3">
            <h1>{category.name}</h1>
          </Col>
          <Col>
            <Row xs={2} sm={3} md={4} className="g-4 mt-1">
              {catalog?.products?.map(
                (product) =>
                  product.categories.includes(category.id) && (
                    <Col key={product.id}>
                      <Card onClick={() => handleClick(product)}>
                        <Card.Img
                          loading="lazy"
                          variant="basic"
                          className="rounded-1"
                          src={product?.image[0]?.url}
                        />
                        <Card.Body>
                          <div className="product-name">{product.name}</div>
                          {product.stock?.available ? (
                            <>
                              {product.stock.price_old && (
                                <div className="discount">скидка</div>
                              )}
                              <div className="price">
                                <div className="value">
                                  {product.stock.sku && "от "}
                                  {Currency(product.stock.price)}
                                </div>
                                <div className="postfix">+</div>
                              </div>
                            </>
                          ) : (
                            <div className="out-of-stock">нет в продаже</div>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  )
              )}
            </Row>
          </Col>
        </Row>
        // </InView>
      ))}

      <ModalProduct
        prefixInCard={"В корзину "}
        productData={{ product: productData }}
        handleCloseModal={handleCloseModal}
        handleReloadBasket={props.handleReloadBasket}
      />
    </Container>
  );
};

export default ListProducts;
