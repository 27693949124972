import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import axios from "axios";
import axiosRetry from "axios-retry";

//обработка Telegram кнопки назад
export function addBackLevel(name, handle) {
  if (!window?.backLevels) {
    window.backLevels = [];
  }

  let f = window.backLevels.filter((val) => val.name === name);
  if (f.length === 0) {
    window.backLevels.push({ name: name, handle: handle });
  }
}

export function delBackLevel(name) {
  if (window?.backLevels) {
    window.backLevels = window.backLevels.filter((val) => val.name !== name);
  }
}

window?.Telegram.WebApp.BackButton.onClick(() => {
  if (window?.backLevels?.length > 0) {
    let back = window.backLevels.pop();
    back.handle();
  } else {
    //если возвращяться некуда, предлагаем закрыть
    window.Telegram.WebApp.showConfirm("Закрыть меню?", (e) => {
      if (e) {
        window.Telegram.WebApp.close();
      }
    });
  }
});

window?.Telegram.WebApp.SettingsButton.show().onClick(() => {
  window.Telegram.WebApp.showConfirm("Закрыть меню?", (e) => {
    //Кнопка настроек!
    if (e) {
      window.Telegram.WebApp.close();
    }
  });
});

//повторные запросы axios
axiosRetry(axios, {
  retries: 1000,
  retryDelay: (retryCount) => Math.min(retryCount * 1000, 15000),
});

axios.defaults.withCredentials = true;

let root = document.getElementById("root");
const reactRoot = ReactDOM.createRoot(root);

export function apiUrl(path, params = "") {
  return "/api/v1/" + path + "/" + params;
}

export function Currency(value) {
  return new Intl.NumberFormat("ru", {
    style: "currency",
    currency: "RUB",
    maximumFractionDigits: 0, // убрать копейки
  }).format(value);
}

const WebApp = window.Telegram.WebApp;

if (WebApp?.initData && window.location.pathname !== "/tgauth/") {
  WebApp.ready();
  WebApp.expand();
  WebApp.BackButton.show();
  WebApp.enableClosingConfirmation();

  // WebApp.MainButton.show();
  // WebApp.MainButton.color = "#6cae17";
  // WebApp.MainButton.text = " главная кнопка";
  // WebApp.MainButton.showProgress(true);
  // WebApp.MainButton.disable();

  // var auth = {
  //   init_data: WebApp.initData, // уникальный идентификатор пользователя
  //   hash: WebApp.initDataUnsafe.hash, // уникальный идентификатор пользователя
  //   //utc: new Date().getTimezoneOffset(), //смещение времени по данным браузера
  // };




  // WebApp.onEvent("invoiceClosed", function (object) {
  //   WebApp.showAlert("invoiceClosed");

  //   if (object.status == "paid") {
  //     // window?.Telegram?.WebApp.close();
  //     WebApp.showAlert("Оплачено успешно! :)");
  //   } else if (object.status == "failed") {
  //     WebApp.showAlert("Не беспокойтесь. Мы сохраним ваш выбор.");
  //   }
  // });

  function setTheme(theme) {
    document.documentElement.setAttribute("class", theme);
    const WebApp = window.Telegram.WebApp;
    if (WebApp?.initData) {
      let bg = getComputedStyle(document.documentElement).getPropertyValue(
        "--theme-btn-active-color"
      );
      //tg.setBackgroundColor(bg);
      WebApp.setHeaderColor(bg);

    }
  }

  //активируем сохраненную тему
  setTheme(localStorage.getItem("theme") || "theme-default");

  //запрашиваем актуальную тему
  axios.get(apiUrl("theme")).then((data) => {
    if (data.data?.theme) {
      setTheme(data.data.theme);
      localStorage.setItem("theme", data.data?.theme);
    }
  });


}

reactRoot.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

if (process.env.NODE_ENV !== "production") {
  //reportWebVitals();
  reportWebVitals(console.log);
}
