import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  Polygon,
  YMaps,
} from "@pbe/react-yandex-maps";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { apiUrl } from "../../index";
import { IconClose } from "../icons/icons";
import Schedule from "../schedule/Schedule";
import { v4 as uuidv4 } from "uuid";

const ModalAddDelivery = (props) => {
  const [show, setShow] = useState(false);
  const [storeValue, setStoreValue] = useState();
  const [addressValue, setAddressValue] = useState();
  const [profileValue, setProfileValue] = useState();
  const [deliveryValue, setDeliveryValue] = useState();
  const [deliveryList, setDeliveryList] = useState();
  const [mapCenter, setMapCenter] = useState([55.751574, 37.603856]);
  const [placemarkCoords, setPlacemarkCoords] = useState();
  const [goodCoords, setGoodCoords] = useState();

  // function handleClickPlacemarkDelivery(delivery) {
  //   setDeliveryValue(delivery);
  // }

  const handleClose = (e) => {
    if (e) {
      try {
        axios.defaults.withCredentials = true;

        let data = {};
        if (profileValue) {
          data["edit"] = profileValue.id;
        }

        data["delivery"] = deliveryValue.id;
        data["address"] = addressValue;
        data["room"] = e.target.room.value;
        data["floor"] = e.target.floor.value;
        data["door"] = e.target.door.value;
        data["description"] = e.target.description.value;
        data["lat"] = placemarkCoords[0];
        data["lon"] = placemarkCoords[1];

        axios
          .post(apiUrl("delivery/update"), data)
          .then((response) => {
            props.handleUpdate(response);
          })
          .catch((error) => {
            props.handleUpdate();
          });
      } catch (err) {
        console.error(err);
      }
    }
    props.handleUpdate(false);
  };

  useEffect(() => {
    for (let n in deliveryList?.store) {
      if (deliveryList?.store[n].id === deliveryValue?.store) {
        setStoreValue(deliveryList?.store[n]);
      }
    }
  }, [deliveryValue]);

  // useEffect(() => {
  //   for (let i in deliveryList?.store) {
  //     for (let j in deliveryList?.store[i].delivery) {
  //       if (
  //         deliveryList?.store[i].delivery[j].id === profileValue?.delivery.id
  //       ) {
  //         let delivery = deliveryList?.store[i].delivery[j];
  //         //console.log("delivery", delivery);
  //         setDeliveryValue(delivery);
  //         break;
  //       }
  //     }
  //   }
  // }, [deliveryList?.store, profileValue]);

  useEffect(() => {
    setShow(props.show);
    setDeliveryList();
    setDeliveryValue();
    setProfileValue();
    if (props.show) {
      try {
        axios.defaults.withCredentials = true;
        axios.get(apiUrl("delivery")).then((data) => {
          setDeliveryList(data.data);
          setProfileValue(props?.profile);

          if (data.data?.delivery?.lat && data.data?.delivery?.lon) {
            setPlacemarkCoords([
              data.data.delivery.lat,
              data.data.delivery.lon,
            ]);
            setAddressValue(data.data.delivery.address);
            setDeliveryValue(data.data.delivery.delivery);
            setGoodCoords(true);
          }
        });
      } catch (err) {
        console.error(err.toJSON());
      }
    }
  }, [props]);

  const map = useRef(null);
  const [ymaps, setYmaps] = useState(null);

  useEffect(() => {
    if (ymaps && map?.current) {
      let bounds = map?.current.geoObjects.getBounds();
      if (bounds) {
        map?.current.setBounds(map?.current.geoObjects.getBounds());
        map?.current.setZoom(Math.min(map?.current.getZoom() - 0.1, 16));
      }
    }
  }, [ymaps, map]);

  function bedAddress(coords) {
    setPlacemarkCoords(coords);
    setGoodCoords(false);
    setAddressValue("");
  }

  // Определяем адрес по координатам (обратное геокодирование).
  function getAddress(delivery, coords) {
    ymaps.geocode(coords).then(function (res) {
      var firstGeoObject = res.geoObjects.get(0);
      setPlacemarkCoords(coords);
      if (firstGeoObject.getPremiseNumber()) {
        setAddressValue(
          firstGeoObject.getThoroughfare() +
            ", " +
            firstGeoObject.getPremiseNumber()
        ); //firstGeoObject.getAddressLine()
        setGoodCoords(true);
        setDeliveryValue(delivery);
      } else {
        bedAddress(coords);
      }
    });
  }

  return (
    <Modal show={show} onHide={handleClose} id="add-delivery">
      <Form
        onSubmit={(e) => {
          handleClose(e);
          e.preventDefault();
        }}
      >
        <Modal.Header className="border-0">
          <Modal.Title>Зона доставки</Modal.Title>
          <Button variant="empty" onClick={() => handleClose(false)}>
            <IconClose className="float-end" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <YMaps query={{ apikey: "9552726f-c895-491c-89b9-e980845b09e3" }}>
            <Map
              instanceRef={map}
              onLoad={(ymapsInstance) => {
                setYmaps(ymapsInstance);
              }}
              id={"map-delivery"}
              width="100%"
              state={{ center: mapCenter, zoom: 11, controls: [] }}
              modules={[
                "control.ZoomControl",
                "control.FullscreenControl",
                "geolocation",
                "geocode",
                "geoObject.addon.hint",
                "geoObject.addon.balloon",
              ]}
              onClick={(e) => {
                bedAddress(e.get("coords"));
              }}
            >
              {deliveryList?.store?.map((store) =>
                store?.delivery.map((delivery) => (
                  <>
                    {delivery.region && (
                      <Polygon
                        key={uuidv4()}
                        onClick={(e) => {
                          getAddress(delivery, e.get("coords"));
                        }}
                        geometry={delivery.region}
                        options={{
                          //interactivityModel: "default#transparent",
                          strokeColor: "rgba(0, 0, 255, 128)",
                          strokeWidth: 2,
                          fillColor: "#00FF00",
                          fillOpacity: 0.1,
                        }}
                      />
                    )}
                  </>
                ))
              )}

              {placemarkCoords &&
                (goodCoords ? (
                  <Placemark
                    geometry={placemarkCoords}
                    options={{
                      iconColor: "#008800",
                    }}
                  />
                ) : (
                  <Placemark
                    geometry={placemarkCoords}
                    options={{
                      iconColor: "#888888",
                    }}
                  />
                ))}

              <FullscreenControl />
              <GeolocationControl options={{ float: "left" }} />
            </Map>
          </YMaps>
          {goodCoords ? ( // deliveryValue
            <div id="adr-delivery">
              <div>
                <b>Время доставки</b>
              </div>
              <Schedule schedule={storeValue?.schedule} />

              <FloatingLabel
                disabled
                readonly={true}
                label="Адрес"
                className="mt-2"
              >
                <Form.Control value={addressValue} disabled={true} />
              </FloatingLabel>

              <Row className="mt-2">
                <Col className="pe-0">
                  <FloatingLabel readonly={true} label="Квартира">
                    <Form.Control
                      placeholder="Квартира"
                      name="room"
                      defaultValue={profileValue?.room}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel readonly={true} label="Этаж">
                    <Form.Control
                      placeholder="Этаж"
                      name="floor"
                      defaultValue={profileValue?.floor}
                    />
                  </FloatingLabel>
                </Col>
                <Col className="ps-0">
                  <FloatingLabel readonly={true} label="Подъезд">
                    <Form.Control
                      placeholder="Подъезд"
                      name="door"
                      defaultValue={profileValue?.door}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <FloatingLabel
                readonly={true}
                label="Комментарий"
                className="mb-3 mt-2"
              >
                <Form.Control
                  placeholder="Комментарий"
                  name="description"
                  defaultValue={profileValue?.description}
                />
              </FloatingLabel>
            </div>
          ) : (
            <div className="mt-3">Выберите помещение в зоне доставки.</div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="start"
            variant="secondary"
            onClick={() => handleClose(false)}
          >
            Отмена
          </Button>
          <Button
            className="end"
            variant="warning"
            type="submit"
            disabled={!Boolean(goodCoords)}
          >
            Сохранить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddDelivery;
