import Button from "react-bootstrap/Button";
import "./Switcher.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useState, useRef, useEffect } from "react";

const Switcher = (props) => {
  const target = useRef(null);
  const [show, setShow] = useState(props.showHint);

  useEffect(() => {
    if (props.value === 0 && props.maxValue === 0) {
      setShow(true);
    }
  }, [props]);

  function handleChange(changeValue) {
    if ("handleSetValue" in props) {
      let newValue = props.value + changeValue;

      if ("minValue" in props) {
        newValue = Math.max(props.minValue, newValue);
      }

      if ("maxValue" in props) {
        if (changeValue === 1 && newValue > props.maxValue) {
          setShow(true);
          setTimeout(() => setShow(false), 1500);
        }
        newValue = Math.min(props.maxValue, newValue);
      }

      if (!(changeValue === 1 && newValue === 0)) {
        props?.handleSetValue(newValue);
      }
    }
  }

  return (
    <div className={"switcher " + props?.className} ref={target}>
      <Button variant="minus" onClick={() => handleChange(-1)}>
        -
      </Button>

      <div className="count">{props?.value}</div>

      <Button variant="plus" onClick={() => handleChange(1)}>
        +
      </Button>

      <Overlay target={target.current} container={target.current} show={show}>
        <Tooltip>осталось {props.maxValue}</Tooltip>
      </Overlay>
    </div>
  );
};

export default Switcher;
