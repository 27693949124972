import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeliveryModalSlot from "./DeliveryModalSlot";
import BasketFooter from "../components/basket/BasketFooter";

const StoreDelivery = (props) => {
  const { basketData, handleUpdateDelivery } = props;

  const [disabledButton, setDisabledButton] = useState(false);
  const [variantId, setVariantId] = useState();
  const [slotList, setSlotList] = useState();
  const [slot, setSlot] = useState();
  //const [slotId, setSlotId] = useState(0);
  const [place, setPlace] = useState();

  const handleToPay = () => {
    if (!disabledButton) {
      setDisabledButton(true);
      for (let key in basketData?.store?.variant) {
        let variant = basketData?.store?.variant[key];
        if (variant.id === variantId && variant.place && !place) {
          let place = document.getElementById(`place-${variant.id}`);
          if (place) {
            place.focus();
          }
          setDisabledButton(false);
          return;
        }
      }
      setTimeout(() => {
        setDisabledButton(false);
      }, 4000);

      handleUpdateDelivery(
        {
          date: slotList?.date,
          time: slot,
        },
        true
      );
    }
  };

  const handleSlotChange = (_slot, _slotlist) => {
    setSlot(_slot);
    // setSlotId(_slotId);
    setSlotList(_slotlist);
  };

  return (
    <>
      <DeliveryModalSlot
        basketData={basketData}
        handleSlotChange={handleSlotChange}
        handleUpdateDelivery={handleUpdateDelivery}
      />

      {slot && (
        <BasketFooter
          basketData={basketData}
          handleToPay={handleToPay}
          disabledButton={disabledButton}
          to={false}
        />
      )}
    </>
  );
};

export default StoreDelivery;
