import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addBackLevel, delBackLevel, apiUrl } from "../index";

import BasketHeader from "../components/basket/BasketHeader";
import BasketFooter from "../components/basket/BasketFooter";
import ListBasket from "./ListBasket";

const Basket = () => {
  const [basketData, setBasket] = useState();

  const [reloadBasketList, setReloadBasketList] = useState();
  const [updateBasketValue, setUpdateBasketValue] = useState();

  function handleReloadBasket(value) {
    setReloadBasketList(value);
  }

  function handleUpdateBasket(value) {
    setUpdateBasketValue(value);
  }

  const navigate = useNavigate();

  useEffect(() => {
    addBackLevel("basket", () => {
      navigate("/");
    });
    return () => {
      delBackLevel("basket");
    }
  }, []);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get(apiUrl("basket")).then((data) => {
      if (data.data?.basket?.length) {
        setBasket(data.data);
      } else {
        navigate("/");
      }
    });
  }, [reloadBasketList]);

  useEffect(() => {
    const abortController = new AbortController();
    if (updateBasketValue) {
      const getData = async () => {
        await axios
          .post(apiUrl("basket/update"), updateBasketValue, {
            signal: abortController.signal,
          })
          .then((data) => {
            if (data.data?.basket?.length > 0) {
              setBasket(data.data);
            } else {
              navigate("/");
            }
          });
      };

      getData();
    }

    return () => {
      abortController.abort();
    };
  }, [updateBasketValue]);

  return (
    <>
      {/* <Header updateBasket={updateBasket}>
        <StoreSelect
          handleUpdate={handleUpdateBasket}
          delivery={basketData?.delivery}
        ></StoreSelect>
      </Header> */}

      <BasketHeader step={1} />

      <ListBasket
        handleUpdateBasket={handleUpdateBasket}
        handleReloadBasket={handleReloadBasket}
        basketData={basketData}
      />

      <BasketFooter basketData={basketData} to={"/basket/delivery/"} />

    </>
  );
};

export default Basket;
