import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { IconPencil } from "../components/icons/icons";
import { apiUrl, Currency } from "../index";
import Switcher from "../components/switcher/Switcher";
import { useDebounce } from "use-debounce";

const ItemBasket = (props) => {
  const { itemData } = props;

  const [newBasketValue, setNewBasketValue] = useState(false);
  const [debouncedNewBasketValue] = useDebounce(newBasketValue, 500);

  function handleProductSetValue(value) {
    itemData.count = value;
    setNewBasketValue({
      basket_id: props?.itemData?.id,
      sku: itemData.sku.id,
      count: itemData.count,
      return: "basket",
    });
  }

  useEffect(() => {
    props.handleUpdateBasket(debouncedNewBasketValue);
  }, [debouncedNewBasketValue]);

  return (
    <Row className="mt-4" key={itemData.id} id={`i-${itemData.id}`}>
      <Col>
        <div className="basket-item">
          <Row onClick={() => props.handleOpenModal(itemData)}>
            <Col xs={4} /*onClick={handleAccordion}*/>
              <Image
                rounded
                fluid
                width={128}
                src={itemData?.product?.image[0]?.url}
              />
            </Col>

            <Col xs={8} className="ps-0 position-relative">
              <Row>
                <Col /*onClick={handleAccordion}*/>
                  {itemData.product.name}
                  {itemData?.sku && ", " + itemData.sku.name}
                  <div className="item-price pe-0 color-notice">
                    <b>{Currency(itemData.sku.stock.price)}</b>
                  </div>
                </Col>
              </Row>
              <div className="hr"></div>

              <div className="edit color-notice">
                Изменить <IconPencil className="me-2 d-inline" />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col className="text-center">
              {(itemData?.exclude?.length > 0 ||
                itemData?.include?.length > 0) && (
                  <div
                    onClick={handleAccordion}
                    className={accordionState ? "detail open" : "detail"}
                  >
                    }
                    <IconAdd />
                  </div>
                )}
            </Col>
          </Row> */}
          {itemData?.includes?.length > 0 && (
            <Row>
              <Col>
                <div
                  className={"accordion"}
                  /*className={accordionState ? "accordion open" : "accordion"}*/
                  id={`accordion-${itemData.id}`}
                >
                  <div className="accordion-container">
                    {itemData?.includes?.map(
                      (item) =>
                        item?.count === 0 &&
                        item?.stock.price === 0 &&
                        item?.сount_default > 0 && (
                          <div key={item.id} className="accordion-line">
                            <div className="accordion-name">
                              <span className="prefix-minus">-</span>
                              {item.name}
                            </div>
                          </div>
                        )
                    )}

                    {itemData?.includes?.map(
                      (item) =>
                        item?.count > 0 &&
                        !(item?.stock.price === 0 &&
                          item?.сount_default > 0) && (
                          <div key={item.id} className="accordion-line">
                            <div className="accordion-name">
                              <span className="prefix-plus">+</span>
                              {item.name}
                            </div>
                            <div className="accordion-value">
                              {Currency(item?.stock?.price)}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="sum">
                <Row>
                  <Col>
                    <b>Итого:</b>

                    <div className="float-end">
                      <Switcher
                        className="d-inline-block me-1"
                        minValue={0}
                        maxValue={itemData?.available}
                        showHint={itemData?.changed}
                        value={itemData.count}
                        handleSetValue={handleProductSetValue}
                      />

                      <span
                        style={{ minWidth: "80px" }}
                        className="d-inline-block text-end color-notice ms-2"
                      >
                        {Currency(itemData?.price * itemData?.count)}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ItemBasket;
