import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addBackLevel, delBackLevel, apiUrl, } from "../index";
import { Container, Col, Row } from "react-bootstrap";

import Footer from "../components/footer";
import Header from "../components/header";
import { Link, NavLink } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const InfoDetail = (props) => {
  const [infoDetail, setInfoDetail] = useState();
  const params = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    addBackLevel("infoDetail", () => {
      navigate("/info/");
    });
    return () => {
      delBackLevel("infoDetail");
    }
  }, []);


  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get(apiUrl("info/" + params.uuid)).then((data) => {
      setInfoDetail(data.data);
    });
  }, []);

  return (
    <>
      <Header titleText={"Информация"} />
      <Container id="content">
        {infoDetail ? (
          <>
            <Row >
              <Col className="mt-4 text-center"><h1>{infoDetail?.title}</h1></Col>
            </Row>
            <Row >
              <Col className="mt-3"><div dangerouslySetInnerHTML={{ __html: infoDetail?.body }} /></Col>
            </Row>
          </>
        ) : (
          <div className="text-center mt-5">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}


      </Container>
      <Footer />
    </>
  );
};

export default InfoDetail;
