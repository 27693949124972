import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { IconClose } from "../components/icons/icons";
import { Currency, apiUrl, addBackLevel, delBackLevel } from "../index";
import Spinner from "react-bootstrap/Spinner";

import { v4 as uuidv4 } from "uuid";

const OrderDetailModalCourier = (props) => {
  const [order, setOrder] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    if (order) {
      addBackLevel("modal", () => {
        handleClose();
      });
    }
    return () => {
      delBackLevel("modal");
    };
  }, [order]);

  useEffect(() => {
    setOrder(props?.order);
    if (props?.order?.uuid) {
      axios
        .get(apiUrl("courier/order/detail/" + props.order.uuid))
        .then((data) => {
          let result = data.data.order;
          result["items"] = data.data.items;
          setOrder(data.data.order);
          setData(data.data);
        });
    }
  }, [props?.order?.uuid]);

  const handleSetOrderStatus = (status) => {
    axios
      .patch(apiUrl("courier/order/patch/" + props.order.uuid), {
        status: status,
      })
      .then((data) => {
        handleClose();
        props.handleGetOrders();
        // let result = data.data.order;
        // result["items"] = data.data.items;
        // setOrder(data.data.order);
      });
  };

  const handleClose = () => {
    props.handleShowModal(false);
    delBackLevel("modal");
  };

  return (
    <>
      <Modal
        show={Boolean(order)}
        onHide={handleClose}
        scrollable={true}
        className="modal-product"
      >
        <Modal.Header className="border-0">
          <Modal.Title>{"Заказ №" + order?.number}</Modal.Title>

          <Button
            variant="empty"
            className="align-self-start"
            onClick={() => handleClose(false)}
          >
            <IconClose className="float-end" />
          </Button>
        </Modal.Header>
        <Modal.Body className={"order-status-" + order?.status}>
          <Row>
            <Col>
              <div className="status"><b>{props?.order?.status_display}</b></div>
              <div className="amount">Стоимость {Currency(order?.amount)}</div>
            </Col>
            <Col>
              <div>{order?.delivery ? "Доставка" : "Выдача"}</div>
              <div className="variant">{order?.variant_display}</div>
              <div className="slot">{order?.slot_display}</div>
            </Col>
          </Row>
          {order?.customer && (
            <Row className="mt-2">
              <Col>
                <b>Заказчик:</b> {order?.customer?.name}
                {order?.customer?.phone && (<a href={"tel:+" + order?.customer?.phone} target={"_blank"} rel="noreferrer">, +{order?.customer?.phone}</a>)}
              </Col>
            </Row>
          )}

          {order?.address && (
            <Row className="mt-2">
              <Col>
                <div className="address"><b>Адрес доставки</b>: {order?.address}</div>
              </Col>
            </Row>
          )}

          <Row className="mt-2 fw-bold">
            <Col>Состав заказа</Col>
          </Row>

          {order?.items ? (
            <>
              {order?.items?.map((item) => (
                <Row key={uuidv4()} className="order-item">
                  <Col xs={12}>{item?.name}</Col>
                  <Col xs={12}>
                    <Row className="mt-1">
                      <Col xs={4} className="exclude">
                        {item?.includes?.map(
                          (exclude) =>
                            exclude.count === 0 && (
                              <div key={uuidv4()}>- {exclude.name}</div>
                            )
                        )}
                      </Col>
                      <Col xs={8} className="include">
                        {item?.includes?.map(
                          (include) =>
                            include.count > 0 && (
                              <div key={uuidv4()}>+ {include.name}</div>
                            )
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <hr className="order-item-hr" />
                    <Row>
                      <Col>
                        {item?.count} Х{" "}
                        {Currency(
                          parseFloat(item?.price) +
                          item?.includes.reduce(function (a, b) {
                            return a + parseFloat(b.price);
                          }, 0)
                        )}
                      </Col>
                      <Col className="text-end fw-bold">
                        {Currency(
                          item?.count *
                          (parseFloat(item?.price) +
                            item?.includes.reduce(function (a, b) {
                              return a + parseFloat(b.price);
                            }, 0))
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <div className="text-center mt-5">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}

          <Row className="mt-3 mb-3 fw-bold">
            <Col>Итого:</Col>{" "}
            <Col className="text-end">{Currency(order?.amount)}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0">
          {data?.next_status?.map((status) => (
            <Button
              key={uuidv4()}
              variant={status.type}
              onClick={() => {
                handleSetOrderStatus(status.code);
              }}
              className={"w-100"}
            >
              {status.name}
            </Button>
          ))}

          <Button variant="basic" onClick={handleClose} className={"w-100"}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderDetailModalCourier;
