import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import StoreSelect from "../components/store/store";
import { apiUrl } from "../index";
import ListProducts from "./ListProducts";
import "./catalog.css";
import { addBackLevel } from "../index";

import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Placeholder from "react-bootstrap/Placeholder";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

//axios.defaults.withCredentials = true;

const Catalog = () => {
  //const [catalog, setCatalog] = useState();
  const [catalog, setCatalog] = useState(
    JSON.parse(localStorage.getItem("catalog"))
  );

  const [updateCatalog, setUpdateCatalog] = useState([]);

  function handleStoreUpdate(value) {
    setUpdateCatalog(value);
    setUpdateBasket(value);
  }

  const [updateBasket, setUpdateBasket] = useState([]);

  function handleReloadBasket(value) {
    setUpdateBasket(value);
  }

  useEffect(() => {
    try {
      axios.defaults.withCredentials = true;
      axios.get(apiUrl("catalog")).then((data) => {
        setCatalog(data.data);
        localStorage.setItem("catalog", JSON.stringify(data.data));
      });
    } catch (err) {
      console.error(err.toJSON());
    }
  }, [updateCatalog]);

  return (
    <>
      <Header titleText={"Меню"} updateBasket={updateBasket}>
        <StoreSelect
          handleUpdate={handleStoreUpdate}
          delivery={catalog?.delivery}
          store={catalog?.store}
        ></StoreSelect>
      </Header>
      <ListProducts handleReloadBasket={handleReloadBasket} catalog={catalog} />
      <Footer />
    </>
  );
};

export default Catalog;
