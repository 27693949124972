import axios from "axios";
import { useEffect, useState } from "react";
import { apiUrl } from "../index";
import "./Profile.css";

import Footer from "../components/footer";
import Header from "../components/header";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";

//import Cookies from "js-cookie";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.withCredentials = true;
// const csrftoken = Cookies.get("x-csrftoken");

const Profile = () => {
  function onFormSubmit(e) {
    e.preventDefault();
  }

  const [profileData, setProfile] = useState([]);

  useEffect(() => {
    try {
      axios.defaults.withCredentials = true;
      axios.get(apiUrl("profile")).then((data) => setProfile(data.data));
    } catch (err) {
      console.error(err.toJSON());
    }
  }, []);

  return (
    <Container id="content">
      <Row>
        <Col>
          <Header />
          <Form onSubmit={onFormSubmit}>
            <h2>Профиль</h2>
            <InputGroup className="mt-3 mb-3">
              <InputGroup.Text>Имя</InputGroup.Text>
              <Form.Control
                defaultValue={profileData[0]?.first_name}
                required
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Отчество</InputGroup.Text>
              <Form.Control
                defaultValue={profileData[0]?.profile?.sur_name}
              ></Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Фамилия</InputGroup.Text>
              <Form.Control
                defaultValue={profileData[0]?.last_name}
              ></Form.Control>
            </InputGroup>
            <Button type="submit">Сохранить не работает</Button>
          </Form>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
