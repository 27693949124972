import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { apiUrl, addBackLevel, delBackLevel } from "../../index";
import Form from "react-bootstrap/Form";
import {
  IconPencil,
  IconTrash,
  IconRadionSelected,
  IconRadion,
  IconSelectPoint,
  IconClose
} from "../icons/icons";

//import { getPlayers } from '../api'
//getPlayers(team).then(setPlayers)

const ModalDelivery = (props) => {

  const [show, setShow] = useState(false);
  const [profileValueId, setProfileValueId] = useState();
  const [profileValue, setProfileValue] = useState();
  const [deliveryList, setDeliveryList] = useState();
  const [deletedProfileId, setDeletedProfileId] = useState();

  useEffect(() => {
    if (show) {
      addBackLevel("modal", () => {
        handleClose(false);
      });
    }
    return () => {
      delBackLevel("modal");
    }
  }, [show]);


  //Изменилась точка доставки
  useEffect(() => {
    deliveryList?.profiles?.forEach((profile) => {
      if (profile.id === profileValueId) {
        setProfileValue(profile);
      }
    });
  }, [deliveryList?.profiles, profileValueId]);

  const handleProfileDelete = (id) => {
    axios
      .post(apiUrl("delivery/update"), {
        delete: id,
      })
      .then((response) => {
        setDeletedProfileId(id);
      });
  };

  const handleClose = (e) => {
    delBackLevel("modal");
    if (e) {
      try {
        axios.defaults.withCredentials = true;
        axios
          .post(apiUrl("delivery/update"), {
            profile: profileValueId,
          })
          .then((response) => {
            props.handleUpdate(response);
          })
          .catch((error) => {
            props.handleUpdate();
          });
      } catch (err) {
        console.error(err.toJSON());
      }
    }
    props.handleUpdate(false);
  };

  useEffect(() => {
    setShow(props.show);
    //setDeliveryList();
    //setProfileValue();
    if (props.show) {
      try {
        axios.defaults.withCredentials = true;
        axios.get(apiUrl("delivery")).then((data) => {
          setDeliveryList(data.data);
          setProfileValueId(0);
          //Установить delivery, если нет взять первый delivery из data.store
          if (data.data?.delivery?.delivery) {
            setProfileValueId(data.data?.delivery?.id);
          } else {
            for (let n in data.data?.profiles) {
              if (data.data?.profiles[n]?.delivery) {
                setProfileValueId(data.data?.profiles[n].id);
                break;
              }
            }
          }
        });
      } catch (err) {
        console.error(err.toJSON());
      }
    }
  }, [props, deletedProfileId]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Form
        onSubmit={(e) => {
          handleClose(e);
          e.preventDefault();
        }}
      >
        <Modal.Header className="border-0">
          <Modal.Title>Выберите адрес</Modal.Title>
          <Button variant="empty" onClick={() => handleClose(false)} >
            <IconClose className="float-end" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ToggleButtonGroup
            vertical={true}
            type="radio"
            name="profile"
            value={profileValue?.id}
            onChange={setProfileValueId}
            className="w-100"
            required
          >
            {deliveryList?.profiles?.map(
              (profile) =>
                profile?.delivery && (
                  <ToggleButton
                    key={profile.id}
                    value={profile.id}
                    id={`profile-${profile.id}`}
                    variant="empty"
                    className="d-table lh-1 mb-2"
                  >
                    <div className="d-table-cell align-middle">
                      {profileValueId === profile.id ? (
                        <IconRadionSelected className=" me-2" />
                      ) : (
                        <IconRadion className="me-2" />
                      )}
                    </div>

                    <div className="d-table-cell w-100 text-start align-middle">
                      {[profile?.address, profile.room, profile.door, profile.floor, profile.description].filter(Boolean).join(", ")}
                    </div>

                    <div className="d-table-cell text-nowrap align-middle">
                      <Button
                        variant="empty"
                        onClick={() => {
                          props.handleProfileEdit(profile);
                        }}
                      >
                        <IconPencil className="me-2" />
                      </Button>
                      <Button
                        variant="empty"
                        onClick={() => {
                          handleProfileDelete(profile.id);
                        }}
                      >
                        <IconTrash />
                      </Button>
                    </div>
                  </ToggleButton>
                )
            )}
          </ToggleButtonGroup>

          {profileValueId === 0 && (
            <div className="text-center mb-2">
              <IconSelectPoint className="d-block" />
              <p className="mt-4 w-75 d-inline-block">
                Чтобы сделать заказ, необходимо добавить адрес!
              </p>
            </div>
          )}

          <div className="text-center">
            <Button
              variant="basic"
              onClick={() => props.handleProfileAdd()}
              className="ps-4 pe-4"
            >
              + Добавить адрес
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="start"
            variant="secondary"
            onClick={() => handleClose(profileValueId < 1)}
          >
            Отмена
          </Button>
          {profileValueId !== 0 && (
            <Button className="end" variant="active" type="submit">
              Выбрать адрес
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalDelivery;
