import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import "./Basket.css";
import { Currency } from "../../index";

import Spinner from "react-bootstrap/Spinner";

const basketFooter = (props) => {
  const { basketData, handleToPay, disabledButton = false, to } = props;
  return (
    basketData?.basket?.length && (
      <div id="footer" className="fixed-bottom">
        <Row className="text-center g-1">
          <Col className="ps-2 pe-2">
            <Link
              className="btn btn-next basket-width {disabledButton}"
              to={to}
              disabled={disabledButton}
              onClick={handleToPay}
            >
              <span className="theme-background-color">
                {disabledButton && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                Далее&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span className={"color-notice"}>
                {Currency(
                  basketData?.total?.price + basketData?.total?.delivery?.price
                )}
              </span>
            </Link>
          </Col>
        </Row>
      </div>
    )
  );
};

export default basketFooter;
