import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addBackLevel, delBackLevel, apiUrl } from "../index";
import { Button, Container, Col, Row } from "react-bootstrap";

import "./Order.css";
import Footer from "../components/footer";
import Header from "../components/header";
import OrderItem from "./OrderItem";
import OrderDetailModal from "./OrderDetailModal";
import { IconVolt, IconHistory } from "../components/icons/icons";
import Spinner from "react-bootstrap/Spinner";
import axiosRetry from "axios-retry";

let archiveCount = 3;

const Order = () => {

  const [orderActiveList, setOrderActiveList] = useState();
  const [orderArchiveList, setOrderArchiveList] = useState();

  const [orderDetail, setOrderDetail] = useState();
  let navigate = useNavigate();


  useEffect(() => {
    addBackLevel("order", () => {
      navigate("/");
    });
    return () => {
      delBackLevel("order");
      archiveCount = 3;
    };
  }, []);

  const handleGetOrders = (params = "") => {
    const client = axios.create();
    axiosRetry(client, { retries: 0 });
    client.get(apiUrl("order/list", "?archive=" + archiveCount)).then((data) => {
      if (data.data?.active?.length || data.data?.archive.length) {
        setOrderActiveList(data.data?.active);
        setOrderArchiveList(data.data?.archive);
      } else {
        navigate("/");
      }
    });
  };

  const handleShowArchive = () => {
    archiveCount = 100;
    handleGetOrders();
  };

  useEffect(() => {
    handleGetOrders();
    let timerId = setInterval(
      () => {
        handleGetOrders();
      }, //0
      10000
    );

    return () => {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    if (Boolean(orderDetail)) {
      orderActiveList?.active?.forEach(function (item, i, arr) {
        if (orderDetail.uuid === item.uuid) {
          setOrderDetail(item);
        }
      });
    }
  }, [orderActiveList]);

  const handleShowModal = (order) => {
    setOrderDetail(order);
  };

  return (
    <>
      <Header titleText={"Заказы"} />
      <Container id="content" className="basket-width">
        {!orderActiveList && (
          <div className="text-center mt-5">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        {orderActiveList && (
          <>
            <Row className="mt-3">
              <Col className="order-header">
                <IconVolt />
                Активные заказы
              </Col>
            </Row>

            {orderActiveList?.map((order) => (
              <OrderItem
                showModal={handleShowModal}
                key={order.uuid}
                order={order}
              />
            ))}
          </>
        )}
        {orderArchiveList && (
          <>
            <Row className="mt-3">
              <Col className="order-header">
                <IconHistory />
                Завершенные заказы
              </Col>
            </Row>

            {orderArchiveList?.map((order) => (
              <OrderItem
                showModal={handleShowModal}
                key={order.uuid}
                order={order}
              />
            ))}

            <Row className="mt-3 text-center">
              <Col>
                <Button onClick={() => handleShowArchive()} variant="basic">
                  Показать ещё
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />
      <OrderDetailModal order={orderDetail} handleShowModal={handleShowModal} />
    </>
  );
};

export default Order;
