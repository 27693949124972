import axios from "axios";
import { useEffect } from "react";
import { apiUrl } from "../index";
import Spinner from "react-bootstrap/Spinner";
import { Container, Col, Row } from "react-bootstrap";

const TelegramAuth = () => {
  useEffect(() => {
    const WebApp = window.Telegram.WebApp;
    if (WebApp?.initData) {
      WebApp.expand();
      var auth = {
        init_data: WebApp.initData, // уникальный идентификатор пользователя
        hash: WebApp.initDataUnsafe.hash, // уникальный идентификатор пользователя
      };
      axios.post(apiUrl("telegram/auth"), auth).then((data) => {
        window.location.href = "/";
      });
    }
  }, []);

  return (
    <Container>
      <Row className="vh-100 text-center">
        <Col className="m-auto">
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </Container>
  );
};

export default TelegramAuth;
