import { IconClock } from "../icons/icons";
import { v4 as uuidv4 } from "uuid";

const Schedule = (props) => {
  return (
    <div>
      <IconClock className="store-info-icon" />
      {props?.schedule?.map((item) => (
        <span key={uuidv4()} className="d-inline-block me-2">
          {item?.start ? (
            <>
              {item.id !== "today" && item.name} {item.start}-{item.end}
            </>
          ) : (
            <span style={{ color: "red" }}>{item.name}</span>
          )}
        </span>
      ))}
    </div>
  );
};

export default Schedule;
