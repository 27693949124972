import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Currency, addBackLevel, apiUrl } from "../index";
import { Container, Col, Row, H1 } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./OrderManager.css";
import Footer from "../components/footer";
import Header from "../components/header";

const OrderItemManager = (props) => {
  const { order } = props;

  const handlerClick = (order) => {
    props.showModal(order);
  };

  return (
    <Row
      onClick={() => handlerClick(order)}
      key={`order-${order?.number}`}
      className={"ms-1 me-1 mt-2 order " + order.status}
    >
      <Col>
        <div className="number">Заказ {order?.number}</div>{" "}
        <div>Стоимость {Currency(order.amount)}</div>
        <div>{order.status_display}</div>
      </Col>
      <Col>
        <div>{order.variant_display}</div>
        <div>{order?.delivery ? "Доставка" : "Выдача"}</div>
        <div>{order.slot_display}</div>
      </Col>
    </Row>
  );
};

export default OrderItemManager;
