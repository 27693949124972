import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  YMaps,
} from "@pbe/react-yandex-maps";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { apiUrl, addBackLevel, delBackLevel } from "../../index";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { v4 as uuidv4 } from "uuid";

import {
  Icon,
  IconCafe,
  IconClock,
  IconTable,
  IconBar,
  IconClose,
} from "../icons/icons";

import Schedule from "../schedule/Schedule";

const ModalStore = (props) => {
  const [show, setShow] = useState(false);
  const [storeValue, setStoreValue] = useState();
  const [deliveryList, setDeliveryList] = useState();
  const [mapCenter, setMapCenter] = useState([55.751574, 37.603856]);

  function handleClickPlacemarkDelivery(store) {
    setStoreValue(store);
  }

  useEffect(() => {
    if (show) {
      addBackLevel("modal", () => {
        handleClose(false);
      });
    }
    return () => {
      delBackLevel("modal");
    };
  }, [show]);

  const handleClose = (e) => {
    delBackLevel("modal");
    if (e) {
      try {
        axios.defaults.withCredentials = true;
        axios
          .post(apiUrl("delivery/update"), {
            store: storeValue.id,
          })
          .then((response) => {
            props.handleUpdate(response);
          })
          .catch((error) => {
            props.handleUpdate();
          });
      } catch (err) {
        console.error(err.toJSON());
      }
    }
    props.handleUpdate(false);
  };

  useEffect(() => {
    if (storeValue?.lon && storeValue?.lat) {
      //setMapCenter([storeValue.lat, storeValue.lon]);
    }
  }, [storeValue]);

  useEffect(() => {
    setShow(props.show);
    setDeliveryList();
    setStoreValue();
    if (props.show) {
      try {
        axios.defaults.withCredentials = true;
        axios.get(apiUrl("delivery")).then((data) => {
          setDeliveryList(data.data);

          //Установить store, если нет взять первый store из data
          if (data.data?.delivery?.store && !data.data?.delivery?.delivery) {
            data.data?.store?.forEach((store) => {
              if (store.id === data.data?.delivery?.store?.id) {
                setStoreValue(store);
              }
            });
          }

          //  else {
          // if (!data.data?.delivery?.store && data.data?.store[0]) {
          //   setStoreValue(data.data?.store[0]);
          // }
          //}
        });
      } catch (err) {
        console.error(err.toJSON());
      }
    }
  }, [props]);

  const map = useRef(null);
  const [ymaps, setYmaps] = useState(null);

  useEffect(() => {
    if (ymaps && map?.current) {
      let bounds = map?.current.geoObjects.getBounds();
      if (bounds) {
        map?.current.setBounds(map?.current.geoObjects.getBounds());
        map?.current.setZoom(Math.min(map?.current.getZoom() - 0.1, 16));
      }
    }
  }, [ymaps, map]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Form
        onSubmit={(e) => {
          handleClose(e);
          e.preventDefault();
        }}
      >
        <Modal.Header className="border-0">
          <Modal.Title>Филиалы</Modal.Title>
          <Button variant="empty" onClick={() => handleClose(false)}>
            <IconClose className="float-end" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {deliveryList?.store && (
            <YMaps query={{ apikey: "9552726f-c895-491c-89b9-e980845b09e3" }}>
              <Map
                instanceRef={map}
                onLoad={(ymapsInstance) => {
                  setYmaps(ymapsInstance);
                }}
                width="100%"
                state={{ center: mapCenter, zoom: 9, controls: [] }}
                modules={["control.ZoomControl", "control.FullscreenControl"]}
              >
                {deliveryList?.store?.map((store) => (
                  <Placemark
                    key={uuidv4()}
                    geometry={[store.lat, store.lon]}
                    properties={{ iconCaption: store.description }}
                    options={{
                      iconColor:
                        store.id === storeValue?.id ? "#ff0000" : "#666666",
                    }}
                    onClick={() => handleClickPlacemarkDelivery(store)}
                  />
                ))}
                <FullscreenControl />
                <GeolocationControl options={{ float: "left" }} />
              </Map>
            </YMaps>
          )}
          {storeValue && (
            <>
              <div className="mt-2 mb-2">
                <b>Адрес</b>
              </div>
              <IconCafe className="store-info-icon" />
              {storeValue?.address}
              <Schedule schedule={storeValue?.schedule} />
            </>
          )}

          {storeValue?.variant?.length > 0 && (
            <div className="mt-4 mb-2">
              <b>Способы получения заказа</b>
            </div>
          )}

          {storeValue?.variant?.map((item) => (
            <div key={`badge-${item.name}`}>
              <Icon variant={item.name} className="store-info-icon" />
              {item.name_display}
            </div>
          ))}

          {/* <Stack direction="horizontal" gap={2}>
            {storeValue?.variant?.map((item) => (
              <Badge key={`badge-${item.name}`} bg="primary">
                {item.name}
              </Badge>
            ))}
          </Stack> */}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="start"
            variant="secondary"
            onClick={() => handleClose(false)}
          >
            Отмена
          </Button>
          {storeValue && (
            <>
              <Button className="end" variant="warning" type="submit">
                Заказать здесь
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalStore;
