import axios from "axios";
import { useEffect, useState } from "react";
import { apiUrl } from "../index";
// import "./Basket.css";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
// import StoreDelivery from "./StoreDelivery";
// import RoomDelivery from "./RoomDelivery";
import { Container, Row } from "react-bootstrap";
import { Col } from "reactstrap";

import BasketHeader from "../components/basket/BasketHeader";

const Payment = () => {
  const [status, setStatus] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.post(apiUrl("order/create")).then((data) => {
      setStatus(data.data);
      document.location.href = data.data?.invoice_link;
      //navigate(data.data?.invoice_link);
    });
  }, []);

  return (
    <>
      {/* <Header>
        <StoreSelect
          handleUpdate={handleUpdateBasket}
          delivery={basketData?.delivery}
        ></StoreSelect>
      </Header> */}

      <BasketHeader step={3} />

      <Container className="mt-3" id="content">
        <Row>
          <Col className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span>Загрузка платёжной системы...</span>
          </Col>
        </Row>
      </Container>

      {/* {typeDelivery === "store" && <StoreDelivery handleUpdate={handleUpdateBasket} basket={basketData} />}
      {typeDelivery === "room" && <RoomDelivery handleUpdate={handleUpdateBasket} basket={basketData} />} */}

      <Footer />
    </>
  );
};

export default Payment;
