import axios from "axios";
import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { apiUrl } from "../index";
import "./header.css";
import { IconBasket, IconCafe, IconCar, IconStar } from "./icons/icons";

const Header = (props) => {
  const [basketInfo, setBasketInfo] = useState();

  useEffect(() => {
    try {
      axios.defaults.withCredentials = true;
      axios.get(apiUrl("basket/info")).then((data) => {
        setBasketInfo(data.data);
        // заполняем данные о количестве товаров в корзине по всему документу
        for (let elem of document.querySelectorAll(".basket.basket-count-value")) {
          elem.textContent = data.data?.count ? " " + data.data?.count : "";
        }
      });
    } catch (err) {
      console.error(err.toJSON());
    }
  }, [props?.updateBasket]);

  return (
    <Container id="header">
      <Row className="text-end mt-2 top-menu">
        <Col className="text-start">
          <Link to={"/"} className="btn btn-basic">
            <IconStar className="float-start me-2" />
            {Math.round(Number(basketInfo?.points ? basketInfo?.points : 0))}
          </Link>
        </Col>
        <Col className="text-center">
          <Link to={"/"}>
            <h1>{props.titleText}</h1>
          </Link>
        </Col>
        <Col className="text-end">
          <Link
            to={basketInfo?.count > 0 ? "/basket/" : "/"}
            className="btn btn-basic"
          >
            <IconBasket className="float-start me-2" />
            {basketInfo?.count}

            {basketInfo?.delivery === "store" && (
              <IconCafe className="float-end ms-2" />
            )}

            {basketInfo?.delivery === "delivery" && (
              <IconCar className="float-end ms-2" />
            )}
          </Link>
        </Col>
      </Row>
      {props.children}
    </Container>
  );
};

export default Header;
