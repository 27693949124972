import { useEffect, useState } from "react";

import { Button, Col, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import BasketFooter from "../components/basket/BasketFooter";

import { Container } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Spinner from "react-bootstrap/Spinner";
import {
  EffectCoverflow,
  Keyboard,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

//import { useNavigate } from "react-router-dom";
import "swiper/css";
import {
  Icon,
  IconClose,
  IconPencil,
  IconRadion,
  IconRadionSelected,
} from "../components/icons/icons";
import "./Delivery.css";

const StoreDelivery = (props) => {
  const { basketData } = props;

  const [showModal, setShowModal] = useState(false);
  const [variantId, setVariantId] = useState();
  const [slotList, setSlotList] = useState();
  const [slot, setSlot] = useState();
  const [slotId, setSlotId] = useState(0);
  const [place, setPlace] = useState();
  const [realIndex, setRealIndex] = useState();
  const [disabledButton, setDisabledButton] = useState(false);

  const handleScheduleChange = (id) => {
    basketData?.store?.schedule?.forEach(function (schedule) {
      if (schedule.id === id) {
        setSlotList(schedule);
        setSlot(schedule?.slot[0]);
      }
    });
  };

  const handleChangePlace = (e) => {
    setPlace(e.target.value);
    props.handleUpdateDelivery({ place: e.target.value });
  };

  const handleVariantChange = (id) => {
    setVariantId(id);
    props.handleUpdateDelivery({ variant: id });
    basketData?.store?.variant?.forEach(function (variant) {
      if (variant.id === id) {
        if (variant?.place) {
          setTimeout(() => {
            let place = document.getElementById(`place-${variant.id}`);
            if (place) {
              place.focus();
            }
          }, 100);
        }
      }
    });
  };

  useEffect(() => {
    let s = true;

    //выбираем слот из сохраненных
    if (basketData?.delivery?.slot) {
      //let m = basketData?.delivery?.slot?.split(" ");

      let date = basketData?.delivery?.slot?.slice(0, 10);
      let time = basketData?.delivery?.slot?.slice(11, 16);

      for (let key in basketData?.store?.schedule) {
        if (
          basketData?.store?.schedule[key]?.date === date &&
          basketData?.store?.schedule[key]?.slot.includes(time)
        ) {
          setSlotList(basketData?.store?.schedule[key]);
          setSlot(time);

          for (let index in basketData?.store?.schedule[key].slot) {
            if (basketData?.store?.schedule[key].slot[index] === time) {
              setSlotId(index);
            }
          }

          s = false;
          break;
        }
      }
    }

    //выбираем первый возможный слот
    for (let key in basketData?.store?.schedule) {
      if (
        s &&
        basketData?.store?.schedule[key]?.slot &&
        basketData?.store?.schedule[key]?.slot.length
      ) {
        setSlotList(basketData?.store?.schedule[key]);
        setSlot(basketData?.store?.schedule[key]?.slot[0]);
        break;
      }
    }

    setPlace(basketData?.delivery?.place);

    setVariantId(
      basketData?.delivery?.variant
        ? basketData?.delivery?.variant
        : basketData?.store?.variant?.at(0)?.id
    );
  }, [basketData]);

  useEffect(() => {
    if (slotList?.slot[slotId]) {
      setSlot(slotList?.slot[slotId]);
      props.handleUpdateDelivery({
        date: slotList?.date,
        time: slotList?.slot[slotId],
      });
    }
  }, [slotId]);

  const handleClose = (save) => {
    setShowModal(false);
    if (save && realIndex !== undefined) {
      setSlotId(realIndex);
    }
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleToPay = () => {
    if (!disabledButton) {
      setDisabledButton(true);
      for (let key in basketData?.store?.variant) {
        let variant = basketData?.store?.variant[key];
        if (variant.id === variantId && variant.place && !place) {
          let place = document.getElementById(`place-${variant.id}`);
          if (place) {
            place.focus();
          }
          setDisabledButton(false);
          return;
        }
      }
      setTimeout(() => {
        setDisabledButton(false);
      }, 4000);

      props.handleUpdateDelivery(
        {
          date: slotList?.date,
          time: slot,
        },
        true
      );
    }
  };

  return props.basketData ? (
    <>
      <Container className="mt-3 basket-width" id="content">
        {basketData?.store?.variant?.length > 0 && (
          <>
            <Row>
              <Col>
                <h2>Способ получения заказа</h2>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <ToggleButtonGroup
                  vertical={true}
                  type="radio"
                  name="deliveryVariant"
                  value={"bar"}
                  className="w-100 select-variant"
                  onChange={handleVariantChange}
                >
                  {basketData?.store?.variant?.map((item) => (
                    <ToggleButton
                      key={`variant-${item.id}`}
                      value={item.id}
                      id={`variant-${item.id}`}
                      className="d-table"
                      variant="empty"
                    >
                      <div className="d-table-cell text-nowrap align-middle">
                        {variantId === item.id ? (
                          <IconRadionSelected />
                        ) : (
                          <IconRadion />
                        )}{" "}
                        <Icon variant={item.name} className="icon-variant" />
                      </div>
                      <div
                        className="d-table-cell
                  w-100
                  text-start
                  align-middle"
                      >
                        {item.name_display}
                      </div>

                      {item.place && (
                        <div className="d-table-cell text-nowrap align-middle">
                          <label className={variantId === item.id ? "" : "d-none"}>
                            <InputGroup
                              style={{ width: "130px" }}
                              className="place-number"
                            >
                              <InputGroup.Text>№</InputGroup.Text>
                              <Form.Control
                                onChange={handleChangePlace}
                                defaultValue={place}
                              />
                              <InputGroup.Text>
                                <IconPencil />
                              </InputGroup.Text>
                            </InputGroup>
                          </label>
                        </div>
                      )}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-3">
          <Col>
            <div className="time-slot" onClick={handleOpen}>
              <div className="time-slot-title">Получить заказ</div>
              <div className="time-slot-block mt-2">
                <div className="time-slot-day">
                  {!slot && "Нет доступного времени"}
                  {slot &&
                    (slot === "now" ? "Сейчас" : slotList?.name + ", " + slot)}
                </div>
                <div className="time-slot-time">
                  изменить <IconPencil />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Modal show={showModal} id="add-delivery">
          <Modal.Header className="border-0">
            <Modal.Title></Modal.Title>
            <Button variant="empty" onClick={() => handleClose(false)}>
              <IconClose className="float-end" />
            </Button>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h2 className="text-center ">
              <b>Когда хотите получить заказ</b>
            </h2>
            <ToggleButtonGroup
              type="radio"
              name="deliveryDate"
              value={slotList?.id}
              className="w-100 mt-3 mb-1 toggle-basic"
              onChange={handleScheduleChange}
            >
              {basketData?.store?.schedule?.map((item) => (
                <ToggleButton
                  key={item.id}
                  value={item.id}
                  id={`delivery-${item.id}`}
                  disabled={item?.slot.length === 0}
                >
                  {item.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <h2 className="mt-4 text-center">Выберите время</h2>

            <Swiper
              className="mySwiper"
              direction={"vertical"}
              slidesPerView={3}
              centeredSlides={true}
              slideToClickedSlide={true}
              initialSlide={slotId}
              spaceBetween={0}
              // effect={"coverflow"}
              // coverflowEffect={{
              //   rotate: 0,
              //   stretch: 0,
              //   depth: 200,
              //      modifier: 1,
              //   slideShadows: false,
              // }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              modules={[
                EffectCoverflow,
                Pagination,
                Keyboard,
                Pagination,
                Navigation,
              ]}
              mousewheel={{
                enabled: true,
              }}
              keyboard={{
                enabled: true,
              }}
              onRealIndexChange={(e) => {
                setRealIndex(e?.realIndex);
              }}
              loop={true}
            >
              {slotList?.slot?.map((item) => (
                <SwiperSlide key={item}>
                  {item.replace("now", "Сейчас")}
                </SwiperSlide>
              ))}
            </Swiper>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button
              className="w-100"
              variant="warning"
              type="submit"
              onClick={() => handleClose(true)}
            >
              Готово
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>

      {slot && (
        <BasketFooter
          basketData={basketData}
          handleToPay={handleToPay}
          disabledButton={disabledButton}
          to={false}
        />
      )}
    </>
  ) : (
    <div className="text-center mt-5">
      <Spinner animation="border" variant="secondary" />
    </div>
  );
};

export default StoreDelivery;
