import "./store.css";
import axios from "axios";
import { apiUrl } from "../../index";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import ModalStore from "./ModalStore";
import ModalDelivery from "./ModalDelivery";
import ModalAddDelivery from "./ModalAddDelivery";
import {
  Icon,
  IconPencil,
  IconLocation,
  IconClose,
  IconClock,
  IconCafe,
  IconTable,
  IconBar,
  IconCar,
} from "../icons/icons";

import Schedule from "../schedule/Schedule";

const DeliverySelect = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [profileEditValue, setProfileEditValue] = useState(false);
  //const [deliveryText, setDeliveryText] = useState();
  const [deliveryValue, setDeliveryValue] = useState();

  const handleSelectDelivery = () => {
    setShowModal(typeDelivery);
  };

  //Выбор способа доставки
  const [typeDelivery, setTypeDelivery] = useState();

  const handleChangeTypeDelivery = (val) => {
    setTypeDelivery(val);

    // Запрашиваем варианты доставки
    axios.get(apiUrl("delivery")).then((data) => {
      let profile_id = false;
      //Выбираем подходящий профиль
      for (let n in data.data?.profiles) {
        let profile = data.data?.profiles[n];

        if (profile.store && profile.delivery && val === "delivery") {
          profile_id = profile.id;
          break;
        }

        if (profile.store && !profile.delivery && val === "store") {
          profile_id = profile.id;
          break;
        }
      }

      if (profile_id) {
        axios
          .post(apiUrl("delivery/update"), {
            profile: profile_id,
          })
          .then((response) => {
            props.handleUpdate(response);
          });
      } else {
        setDeliveryValue("");
        //Если не нашли подходящий вариант обслуживания, открываем окно
        //setShowModal(val);
      }
    });
  };

  useEffect(() => {
    if (props?.delivery || props?.delivery === null) {
      setTypeDelivery(props?.delivery?.delivery ? "delivery" : "store");
      if (props?.delivery !== null) {
        if (props?.delivery?.delivery) {
          setDeliveryValue("delivery");
        } else {
          setDeliveryValue("store");
        }
      } else {
        setDeliveryValue("");
      }
    }
  }, [props?.delivery]);

  //Ответ от модального окна
  function handleUpdate(r) {
    if (r) {
      props.handleUpdate(r);
    }
    setShowModal();
  }

  function handleProfileAdd() {
    setProfileEditValue();
    setShowModal("addDelivery");
  }

  function handleProfileEdit(profile) {
    setProfileEditValue(profile);
    setShowModal("addDelivery");
  }

  return props?.delivery !== undefined ? (
    <>
      {props?.delivery?.store && props?.delivery?.delivery_enabled && (
        <Row className="mt-2 group-delivery">
          <Col>
            <ToggleButtonGroup
              type="radio"
              id="typeDelivery"
              name="typeDelivery"
              value={typeDelivery}
              onChange={handleChangeTypeDelivery}
              className="w-100 toggle-basic"
            >
              <ToggleButton
                id="select-store"
                value={"store"}
                className="w-50"
                variant="delivery"
                //  onClick={() => setShowModal("store")}
              >
                Заберу лично
              </ToggleButton>
              <ToggleButton
                id="select-delivery"
                value={"delivery"}
                className="w-50"
                variant="delivery"
                //  onClick={() => setShowModal("delivery")}
              >
                Нужна доставка
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      )}
      <Row className="mt-2 group-delivery">
        <Col>
          {deliveryValue === "store" && (
            <Dropdown>
              <Dropdown.Toggle
                variant="delivery-select"
                className={"w-100 text-start"}
                id="dropdown-basic"
              >
                {" "}
                <div
                  className="text-truncate float-start"
                  style={{ width: "calc( 100% - 25px )" }}
                >
                  {props?.delivery?.store?.name}{" "}
                  {props?.delivery?.store?.address}
                </div>
                {/* {props?.delivery.store && props?.delivery.delivery && ( */}
                <div
                  className="float-end pe-2"
                  onClick={(e) => {
                    handleSelectDelivery();
                    e.stopPropagation();
                  }}
                >
                  <IconPencil />
                </div>
                {/* )} */}
              </Dropdown.Toggle>

              <Dropdown.Menu className={"w-100 text-start"}>
                <Dropdown.Item className="float-end dropdown-close">
                  <IconClose />
                </Dropdown.Item>

                {props?.store && (
                  <>
                    <div className="mt-2 mb-2">
                      <b>Адрес</b>
                    </div>
                    <div>
                      <IconCafe className="store-info-icon" />
                      {props?.store?.address}
                    </div>
                    <Schedule schedule={props?.store?.schedule} />
                    <div className="mt-4 mb-2">
                      <b>Способы получения заказа</b>
                    </div>
                  </>
                )}

                {props?.store?.variant?.map((item) => (
                  <div key={`badge-${item.name}`}>
                    <Icon variant={item.name} className="store-info-icon" />
                    {item.name_display}
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {deliveryValue === "delivery" && (
            <Dropdown>
              <Dropdown.Toggle
                variant="delivery-select"
                className={"w-100 text-start"}
                id="dropdown-basic"
              >
                {/* {props.delivery?.delivery?.address} {props.delivery.room} */}
                {[
                  props?.delivery?.address,
                  props?.delivery?.room,
                  props?.delivery?.door,
                  props?.delivery?.floor,
                  props?.delivery?.description,
                ]
                  .filter(Boolean)
                  .join(", ")}

                <div
                  className="float-end pe-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectDelivery();
                  }}
                >
                  <IconPencil />
                </div>
                {/* <IconPencil className="float-end" />
                <IconInfo className="float-end me-1" /> */}
              </Dropdown.Toggle>

              <Dropdown.Menu className={"w-100 text-start"}>
                <Dropdown.Item className="float-end dropdown-close">
                  <IconClose />
                </Dropdown.Item>
                {props?.store && (
                  <>
                    <div className="mt-2 mb-2">
                      <b>Адрес</b>
                    </div>
                    <IconCafe className="store-info-icon" />
                    {props.delivery?.delivery?.address} {props.delivery.room}
                    <Schedule schedule={props?.store?.schedule} />
                    <div className="mt-4 mb-2">
                      <b>Время доставки</b>
                    </div>
                    <IconCar className="store-info-icon" />
                    {props?.store?.delivery_time &&
                      props?.store?.delivery_time > 0 &&
                      "от " + props?.store?.delivery_time / 60 + " минут"}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {deliveryValue === "" && (
            <Button
              className={
                "w-100 text-start " + (deliveryValue === "" && " note")
              }
              variant="delivery-select"
              onClick={handleSelectDelivery}
            >
              Выберите адрес
              <IconLocation className="float-end me-3" />
            </Button>
          )}

          <ModalDelivery
            show={showModal === "delivery"}
            handleProfileAdd={handleProfileAdd}
            handleProfileEdit={handleProfileEdit}
            handleUpdate={handleUpdate}
          />

          <ModalAddDelivery
            show={showModal === "addDelivery"}
            profile={profileEditValue}
            handleUpdate={handleUpdate}
          />

          <ModalStore
            show={showModal === "store"}
            handleUpdate={handleUpdate}
          />
        </Col>
      </Row>
    </>
  ) : (
    // Ожидаем данные
    <Row>
      <Col>
        <Placeholder as={Button} className="w-100 mt-2" xs={4} />
        <Placeholder as={Button} className="w-100 mt-2" xs={4} />
      </Col>
    </Row>
  );
};

export default DeliverySelect;
