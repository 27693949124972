import axios from "axios";
import { useEffect, useState } from "react";

import { Alert, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BasketHeader from "../components/basket/BasketHeader";

import StoreSelect from "../components/store/store";
import { addBackLevel, delBackLevel, apiUrl } from "../index";
import DeliveryDelivery from "./DeliveryDelivery";
import DeliveryStore from "./DeliveryStore";
import { useNavigate } from "react-router-dom";

const Delivery = () => {
  const [basketData, setBasket] = useState(false);
  const [updateBasket, setUpdateBasket] = useState();
  const navigate = useNavigate();

  function handleUpdateBasket(r) {
    setUpdateBasket(r);
  }

  useEffect(() => {
    addBackLevel("delivery", () => {
      navigate("/basket/");
    });
    return () => {
      delBackLevel("delivery");
    };
  }, []);

  function handleInvoiceClosed(status) {
    if (status === "paid") {
      //переход в заказы через одну секунду
      setTimeout(() => {
        navigate("/order/");
      }, 1000);
    } else if (status === "cancelled") {
      navigate("/basket/delivery/");
    } else {
      window.Telegram.WebApp.showAlert("Произошла ошибка: " + status);
      setTimeout(() => {
        navigate("/basket/");
      }, 1000);
    }
  }

  const handleUpdateDelivery = (data, pay = false) => {
    if (basketData?.delivery?.id) {
      axios
        .patch(apiUrl("delivery/patch/" + basketData?.delivery?.id), data)
        .then((result) => {
          //переходим на страницу оплаты
          if (pay) {
            axios.defaults.withCredentials = true;
            axios.post(apiUrl("order/invoce")).then((data) => {
              if (window?.Telegram?.WebApp?.initData) {
                window.Telegram.WebApp.openInvoice(
                  data.data?.invoice_link,
                  handleInvoiceClosed
                );
              } else {
                document.location.href = data.data.invoice_link;
                //window.location.assign(data.data?.invoice_link);
              }
            });
          }
        });
    }
  };

  //Выбор способа доставки
  const [typeDelivery, setTypeDelivery] = useState();

  useEffect(() => {
    let oldBasketData = basketData;
    axios.defaults.withCredentials = true;
    axios.get(apiUrl("basket")).then((data) => {
      if (
        oldBasketData &&
        data.data?.total?.price + data.data?.total?.delivery?.price !==
          oldBasketData?.total?.price + oldBasketData?.total?.delivery?.price
      ) {
        data.data.total.alert = true;
      }

      setBasket(data.data);

      if (data.data?.delivery || data.data?.delivery === null) {
        setTypeDelivery(data.data?.delivery?.delivery ? "delivery" : "store");
      }
    });
  }, [updateBasket]);

  return (
    <>
      <meta httpEquiv="cache-control" content="no-cache" />
      <meta httpEquiv="expires" content="0" />
      <meta httpEquiv="pragma" content="no-cache" />

      <BasketHeader step={2} />
      <Container className="mt-4 basket-width">
        <h2>
          {typeDelivery === "store" && "Заказываю здесь"}
          {typeDelivery === "delivery" && "Доставить сюда"}
        </h2>

        <StoreSelect
          handleUpdate={handleUpdateBasket}
          delivery={basketData?.delivery}
          store={basketData?.store}
        ></StoreSelect>

        {basketData?.total?.alert && (
          <Row className="mt-2">
            <Col>
              <Link to={"/basket/"}>
                <Alert variant={"basic"}>
                  Проверьте количество и стоимость товаров корзины, некоторые
                  позиции изменились
                </Alert>
              </Link>
            </Col>
          </Row>
        )}

        {typeDelivery === "store" && (
          <DeliveryStore
            // handleUpdate={handleUpdateBasket}
            handleUpdateDelivery={handleUpdateDelivery}
            basketData={basketData}
          />
        )}

        {typeDelivery === "delivery" && (
          <DeliveryDelivery
            // handleUpdate={handleUpdateBasket}
            handleUpdateDelivery={handleUpdateDelivery}
            basketData={basketData}
          />
        )}
      </Container>
    </>
  );
};

export default Delivery;
