import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Placeholder from "react-bootstrap/Placeholder";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { apiUrl, Currency, addBackLevel, delBackLevel } from "../index";
import { useEffect, useState } from "react";
import Switcher from "../components/switcher/Switcher";
import Spinner from "react-bootstrap/Spinner";
import { IconClose, IconChevronDownSmall } from "../components/icons/icons";

const ModalProduct = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [productData, setProductData] = useState(false);
  const [currentSku, setСurrentSku] = useState();

  const [excludesList, setExcludesList] = useState([]);
  const [excludesValue, setExcludesValue] = useState([]);

  const handleExcludeChange = (val) => setExcludesValue(val);

  const [includesValue, setIncludesValue] = useState([]);

  const handleIncludeChange = (val) => setIncludesValue(val);

  const [countProductValue, setCountProductValue] = useState(1);
  const [countProductMax, setCountProductMax] = useState(1);

  const setСurrentSkuById = (id) => {
    productData?.sku?.forEach((sku) => {
      if (sku.id === id) {
        setСurrentSku(sku);
      }
    });
  };

  const handleProductSetValue = (value) => {
    setCountProductValue(value);
  };

  const [bigDescription, setBigDescription] = useState(false);
  const [descriptionState, setDescriptionState] = useState(false);

  function handleDescription() {
    if (bigDescription) {
      setDescriptionState(!descriptionState);
    }
  }

  const [priceValue, setPriceValue] = useState(0);
  const [priceOldValue, setPriceOldValue] = useState(0);

  const handleClose = () => {
    setShowDialog(false);
    setProductData(false);
    props.handleCloseModal(productData);
    delBackLevel("modal");
  };

  function pruductIncludes() {
    if (productData?.includes) {
      return productData.includes
        .map((el) => {
          let count = 0;
          if (el.count_default > 0 && el.stock.price === 0) {
            count = excludesValue.includes(el.id) ? 0 : 1;
          } else {
            count = includesValue.includes(el.id) ? 1 : 0;
          }
          return { id: el.id, count: count };
        })
        .filter((el) => el.count !== false);
    }
    return [];
  }

  useEffect(() => {
    if (props?.productData?.product?.id) {
      setShowDialog(true);
      setProductData(props?.productData.product);
      setСurrentSku();
      setExcludesValue([]);
      setIncludesValue([]);
      setDescriptionState(false);
      setCountProductValue(props?.productData?.count || 1);

      axios.defaults.withCredentials = true;
      axios
        .get(apiUrl("product/" + props.productData.product.uuid))
        .then((data) => {
          setProductData(data.data);
          //заполнение добавок по товару или корзине
          let includes, excludes;
          if (props?.productData?.includes) {
            includes = props?.productData?.includes?.map((item) =>
              item.count ? item.id : false
            );
            excludes = props?.productData?.includes?.map((item) =>
              item.сount_default > 0 && item.count == 0 ? item.id : false
            );
          } else {
            includes = data.data.includes?.map((item) =>
              item.count_default ? item.id : false
            );
            excludes = data.data.includes?.map((item) =>
              item.count_default ? false : item.id
            );
          }

          setExcludesList(
            data.data.includes.filter(
              (item) => item.count_default > 0 && item.stock.price === 0
            )
          );

          setIncludesValue(includes);
          setExcludesValue(excludes);

          //выбираем sku, из productData или по умолчанию
          let sku_id =
            props?.productData?.sku?.id ||
            data.data.sku[data.data.sku.length > 1 ? 1 : 0].id;

          data.data?.sku?.forEach(function (sku) {
            if (sku.id === sku_id) {
              setСurrentSku(sku);
            }
          });
        });
    }
  }, [props]);

  useEffect(() => {
    var price = 0;
    var price_old = 0;

    //расчет стоимости товара
    price += currentSku?.stock?.price;
    price_old += currentSku?.stock?.price_old
      ? currentSku?.stock?.price_old
      : currentSku?.stock?.price;

    //расчет стоимости добавок
    pruductIncludes()?.forEach(function (is) {
      productData?.includes?.forEach(function (ia) {
        if (ia.id === is.id) {
          price += ia.stock?.price * is.count;
          price_old += ia.stock?.price_old
            ? ia.stock?.price_old * is.count
            : ia.stock?.price * is.count;
        }
      });
    });

    setPriceValue(price * countProductValue);
    setPriceOldValue(price_old > 0 ? price_old * countProductValue : "");

    // расчет остатков
    let countMax =
      currentSku?.stock?.available -
      (props?.basketReserve ? currentSku?.stock?.basket_reserve : 0);

    if (props?.productData?.sku?.id === currentSku?.id) {
      countMax += props?.productData?.count || 0;
    }

    // расчет остатков добавок

    pruductIncludes()?.forEach(function (is) {
      productData?.includes?.forEach(function (ia) {
        //если добавки включены в состав учитываем его остатки

        if (ia.id === is.id) {
          let includeCountMax =
            ia.stock.available -
            (props?.basketReserve ? currentSku?.stock?.basket_reserve : 0) +
            (props?.productData?.count || 0);

          countMax = Math.min(countMax, includeCountMax);
        }
      });
    });

    setCountProductMax(countMax);

    if (countMax > 0) {
      setCountProductValue(
        Math.min(countProductValue, countMax, currentSku?.stock?.available)
      );
    }

    calc_description();
  }, [
    productData,
    currentSku,
    excludesValue,
    includesValue,
    countProductValue,
  ]);

  function handleAddToBasket() {
    setShowDialog(false);

    axios
      .post(apiUrl("basket/update"), {
        basket_id: props?.productData?.id || null, //productData?.id,
        sku: currentSku?.id,
        count: countProductValue,
        //exclude: excludeValue,
        includes: pruductIncludes(),
      })
      .then(() => {
        props.handleCloseModal(productData);
        props?.handleReloadBasket(productData);
      });
  }

  useEffect(() => {
    if (showDialog) {
      addBackLevel("modal", () => {
        handleClose();
      });
    }
    return () => {
      delBackLevel("modal");
    };
  }, [showDialog]);

  function calc_description() {
    //определяем высоту аккордеон
    let el = document.getElementById(`description-${productData?.id}`); // productData?.product?.id
    if (el) {
      let height = el.scrollHeight;
      el.style.setProperty("--max-description-height", height + "px");
      setBigDescription(height > 50);
    }
  }

  return (
    <Modal
      show={showDialog}
      onHide={handleClose}
      scrollable={true}
      className="modal-product"
    >
      <Modal.Header className="border-0">
        {true ? ( //currentSku productData?.product?.name
          <Modal.Title>
            {productData?.name}
            {currentSku && ", " + currentSku?.name}
          </Modal.Title>
        ) : (
          <Placeholder as={Modal.Title} xs={6}></Placeholder>
        )}

        <Button
          variant="empty"
          className="align-self-start"
          onClick={() => handleClose(false)}
        >
          <IconClose className="float-end" />
        </Button>
      </Modal.Header>

      <Modal.Body className="text-center">
        <Carousel controls={false} touch={true}>
          {productData?.image?.map(
            (
              image // productData?.product?.image?
            ) => (
              <Carousel.Item key={image?.url}>
                <Image src={image?.url} rounded className="mw-100" />
              </Carousel.Item>
            )
          )}
        </Carousel>

        <div
          className={
            "mt-3 product-description" + (descriptionState ? " open" : "")
          }
        >
          <div
            className={"text"}
            id={`description-${productData?.id}`}
            onClick={handleDescription}
          >
            {productData?.description}
          </div>
          {bigDescription && (
            <Button
              className={"button"}
              onClick={handleDescription}
              variant="empty"
            >
              <IconChevronDownSmall />
            </Button>
          )}
        </div>
        {!currentSku && (
          <Spinner animation="border" variant="secondary" className="mt-4" />
        )}

        {productData?.sku?.length > 1 && (
          <>
            <h2 className="mt-3"> Размер продукта</h2>
            <ToggleButtonGroup
              type="radio"
              name="sku"
              value={currentSku?.id}
              className="button-group-sku mt-2"
              onChange={setСurrentSkuById}
            >
              {productData?.sku?.map((sku) => (
                <ToggleButton
                  key={`sku-${sku.id}`}
                  id={`sku-${sku.id}`}
                  value={sku.id}
                >
                  {sku.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </>
        )}

        {excludesList.length > 0 && (
          <>
            <h2 className="mt-3">Убрать из состава</h2>
            <ToggleButtonGroup
              type="checkbox"
              name="exclude"
              value={excludesValue}
              onChange={handleExcludeChange}
              variant="empty"
              className="button-group-exclude mt-2"
            >
              {productData?.includes?.map(
                (exclude) =>
                  exclude.count_default > 0 &&
                  exclude.stock.price === 0 &&
                  exclude.stock?.available > 0 && (
                    <ToggleButton
                      key={`exclude-${exclude.id}`}
                      id={`exclude-${exclude.id}`}
                      value={exclude.id}
                      variant="light"
                      className={
                        excludesValue?.includes(exclude.id) && "strike"
                      }
                    >
                      {exclude.name}
                    </ToggleButton>
                  )
              )}
            </ToggleButtonGroup>
          </>
        )}
        {includesValue.length > 0 && (
          <>
            <h2 className="mt-3">Добавить по вкусу</h2>
            <ToggleButtonGroup
              vertical
              type="checkbox"
              name="include"
              id="includes"
              value={includesValue}
              onChange={handleIncludeChange}
              className="button-group-include w-100"
            >
              {productData?.includes?.map(
                (include) =>
                  !(include.count_default > 0 && include.stock.price === 0) &&
                  include.stock?.available > 0 && (
                    <ToggleButton
                      key={`include-${include.id}`}
                      id={`include-${include.id}`}
                      value={include.id}
                      variant="empty"
                      className={
                        includesValue?.includes(include.id) && "include"
                      }
                    >
                      <div className="d-flex">
                        <div className="name">{include.name}</div>
                        <div className="price">
                          {include?.stock?.price > 0 ? "+" : ""}
                          {Currency(include?.stock?.price)}
                        </div>
                      </div>
                    </ToggleButton>
                  )
              )}
            </ToggleButtonGroup>
          </>
        )}
        <div className="mb-4"></div>
      </Modal.Body>

      <Modal.Footer className="border-0">
        {countProductMax > 0 ? (
          <>
            <Switcher
              className=""
              value={countProductValue}
              minValue={1}
              maxValue={countProductMax}
              handleSetValue={handleProductSetValue}
            />
            <div className="float-end m-0">
              {/* {priceValue !== priceOldValue && (
                  <div className="old-price">{Currency(priceOldValue)}</div>
                )} */}
              <Button variant="next" onClick={handleAddToBasket}>
                {props?.prefixInCard}
                {Currency(priceValue)}
              </Button>
            </div>
          </>
        ) : (
          <div className="float-end m-0">
            {currentSku && <>Нет в наличии</>}
            <Button className="ms-3" variant="warning" onClick={handleClose}>
              Закрыть
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProduct;
