import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";
import "./Basket.css";

const BasketHeader = (props) => {
  return (
    <Container id="basket-header">
      <Row className="text-end mt-2 top-menu">
        <Col className={"text-center step-" + props?.step}>
          <NavLink
            to={"/basket/"}
            className={(navData) => (navData.isActive ? "step active" : "step")}
          >
            <div className="text">Мой заказ</div>
            <div className="circle">1</div>
          </NavLink>

          <NavLink
            to={"/basket/delivery/"}
            className={(navData) => (navData.isActive ? "step active" : "step")}
          >
            <div className="text">Детали</div>
            <div className="circle">2</div>
          </NavLink>

          <span className={"step"}>
            <div className="text">Оплата</div>
            <div className="circle">3</div>
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default BasketHeader;
