import axios from "axios";
import { Button, Container, Alert } from "react-bootstrap";
import ItemBasket from "./ItemBasket";
import ModalProduct from "../appCatalog/ModalProduct";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { apiUrl, Currency } from "../index";
import { Link } from "react-router-dom";
import { IconTrash } from "../components/icons/icons";
import Spinner from "react-bootstrap/Spinner";

const ListBasket = (props) => {
  const { basketData } = props;
  const [productData, setProductData] = useState();

  function handleOpenModal(product) {
    setProductData(product);
  }

  function handleCloseModal(data) {
    setProductData();
  }

  function handleClearBasket() {
    props.handleUpdateBasket({ count: 0 });
  }

  return (
    <Container className="basket basket-width" id="content">
      <Row className="mt-4">
        <Col>
          <h2>Заказ</h2>
        </Col>
        <Col className="text-end">
          <Button onClick={handleClearBasket} variant="empty" className="ps-0">
            <IconTrash />
          </Button>
        </Col>
      </Row>

      {basketData?.total?.alert && (
        <Row className="mt-2">
          <Col>
            <Alert variant={"basic"}>
              Проверьте количество и стоимость товаров корзины, некоторые
              позиции изменились
            </Alert>
          </Col>
        </Row>
      )}

      {basketData?.basket?.length ? (
        <>
          {basketData?.basket?.map((item) => (
            <ItemBasket
              key={item.id}
              itemData={item}
              handleOpenModal={handleOpenModal}
              handleUpdateBasket={props.handleUpdateBasket}
            />
          ))}

          {basketData?.delivery?.delivery && (
            <>
              <Row className="mt-4">
                <Col>Стоимость доставки</Col>
                <Col className="text-end">
                  {Currency(basketData?.total?.delivery?.price)}
                </Col>
              </Row>

              {basketData?.total?.delivery?.next_level > 0 && (
                <Row className="">
                  <Col>
                    До доставки за{" "}
                    {Currency(basketData?.total?.delivery?.next_price)} не
                    хватает{" "}
                    {Currency(
                      basketData?.total?.delivery?.next_level -
                        basketData?.total?.delivery?.price
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ) : (
        <div className="text-center mt-5">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}

      <ModalProduct
        prefixInCard={"Сохранить "}
        productData={productData}
        basketReserve={true}
        handleCloseModal={handleCloseModal}
        handleReloadBasket={props.handleReloadBasket}
      />
    </Container>
  );
};

export default ListBasket;
