import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { addBackLevel, delBackLevel, apiUrl } from "../index";
import { Container, Col, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import Footer from "../components/footer";
import Header from "../components/header";
import { NavLink } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { IconDownload } from "../components/icons/icons";

const Info = () => {
  const [infoList, setInfoList] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    addBackLevel("info", () => {
      navigate("/");
    });
    return () => {
      delBackLevel("info");
    }
  }, []);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get(apiUrl("info")).then((data) => {
      setInfoList(data.data);
    });
  }, []);

  return (
    <>
      <Header titleText={"Информация"} />
      <Container id="content" className="mt-4 basket-width">
        {infoList ?
          (infoList?.map((info) => (

            <Row key={uuidv4()} className="mt-3">

              <Col>
                <NavLink to={"/info/" + info.uuid + "/"} className={"w-100"}>

                  {info.title}<IconDownload className="ms-1 d-inline float-end" />
                </NavLink>
              </Col>

            </Row>

          ))) : (
            <div className="text-center mt-5">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}

      </Container >
      <Footer />
    </>
  );
};

export default Info;
